<template>
  <div id="submitInfo" v-cloak>
    <div class="public-title">
      <span>污染点详情</span>
    </div>
    <div class="listAll">
      <div>
        <template v-for="(item, index) in viewData">
          <NewPageItem
            v-if="item.num % 2 === 0"
            :item="item"
            :index="index"
            :key="index"
          />
        </template>
      </div>
      <div>
        <template v-for="(item, index) in viewData">
          <NewPageItem
            v-if="item.num % 2 === 1"
            :item="item"
            :index="index"
            :key="index"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import NewPageItem from "./NewPageItem.vue";
export default {
  components: { NewPageItem },
  data() {
    return {
      //   result: {},
      viewData: {
        名称: {
          name: "名称",
          icon: "f f-mingcheng",
          type: "text",
          data: "",
          num: 0
        },
        整治状态: {
          name: "整治状态",
          icon: "f f-zhengzhishouduan",
          type: "text",
          data: "",
          num: 1
        },
        地点: {
          name: "地点",
          icon: "f f-didian",
          type: "text",
          data: "",
          num: 0
        },
        治理施工单位: {
          name: "治理施工单位",
          icon: "f f-mingcheng",
          type: "text",
          data: "",
          num: 1
        },
        污染类型: {
          name: "污染类型",
          icon: "f f-wuranleixing",
          type: "text",
          data: "",
          num: 0
        },
        整改及处罚措施: {
          name: "整改及处罚措施",
          icon: "f f-zhenggaijichufacuoshi",
          type: "list",
          data: [],
          num: 1
        },
        时间: {
          name: "时间",
          icon: "f f-shijian",
          type: "list",
          data: [],
          num: 0
        },
        相关文章: {
          name: "相关文章",
          icon: "f f-xiangguanwenzhang",
          type: "url",
          data: [],
          num: 1
        },
        现场描述: {
          name: "现场描述",
          icon: "f f-miaoshu",
          type: "text",
          data: "",
          num: 0
        },
        污染行为: {
          name: "污染行为",
          icon: "f f-wuranxingwei",
          type: "text",
          data: "",
          num: 0
        },
        现场环境图片: {
          name: "现场环境图片",
          icon: "f f-img",
          type: "imgs",
          data: [],
          num: 1
        },
        责任主体: {
          name: "责任主体",
          icon: "f f-zerenzhuti",
          type: "text",
          data: "",
          num: 0
        },
        整治后照片: {
          name: "整治后照片",
          icon: "f f-img",
          type: "imgs",
          data: [],
          num: 1
        },
        举报途径: {
          name: "举报途径",
          icon: "f f-jubaotujing",
          type: "list",
          data: [],
          num: 0
        },
        附件: {
          name: "附件",
          icon: "f f-file",
          type: "file",
          data: [],
          num: 1
        },
        法律手段: {
          name: "法律手段",
          icon: "f f-falvshouduan",
          type: "list",
          data: [],
          num: 0
        },
        备注: {
          name: "备注",
          icon: "f f-mingcheng",
          type: "text",
          data: "",
          num: 1
        }
      }
    };
  },
  // https://huanbaoditu.com/Uploads/wxMini/Image/oQzB4v3ZPABIMnqyBYuwUpMqKZHw/20191101174903730_原图.jpg
  props: ["id", "type"],
  mounted() {
    // console.log(this.id, this.type);

    function delNUll(arr) {
      let newArr = [];
      arr.map(e => {
        if (e !== "" && e != undefined) {
          newArr.push(e);
        }
      });
      return newArr;
    }
    this.$http
      .get("/report/api/GetReportModel", {
        params: {
          id: this.id,
          isview: "0",
          type: this.type
        }
      })
      .then(res => {
        if (res.data.state) {
          //   console.log(res);
          const r = res.data.data;
          //   r.ValidFileList.forEach((item, index) => {
          //     var fileSufixNameObj = {};
          //     var suffixArr = item.split(".");
          //     var sufix = suffixArr[suffixArr.length - 1];

          //     fileSufixNameObj.path = item;
          //     fileSufixNameObj.sufix = sufix;
          //     r.ValidFileList[index] = fileSufixNameObj;
          //   });
          //   this.result = r;
          const GovernState = {
            GovernState_0: "未治理",
            GovernState_1: "整治中",
            GovernState_2: "已整治"
          };
          this.viewData.名称.data = r.Title;
          this.viewData.整治状态.data =
            GovernState[`GovernState_${r.GovernState}`]; // 0->未治理 1->整治中 2->已整治 ${r.Address}  ${r.Province} ${r.City} ${r.Area} ${r.Township}
          this.viewData.地点.data = `${r.Address}`;
          this.viewData.治理施工单位.data = r.GovernCompany;
          this.viewData.污染类型.data = r.PolluteTypeName;
          this.viewData.整改及处罚措施.data = r.PunishWay.split(",") || [];
          this.viewData.整改及处罚措施.data.forEach((e, i, arr) => {
            switch (e) {
              case "罚款":
                arr[i] += ` : ${r.PunishFee}`;
                break;
            }
          });
          if (r.PunishOtherWay) {
            this.viewData.整改及处罚措施.data.push(r.PunishOtherWay);
          }
          this.viewData.整改及处罚措施.data = delNUll(
            this.viewData.整改及处罚措施.data
          );
          this.viewData.时间.data = [
            `调查发现：${r.FindingTime}`,
            `举报/倡导：${r.ReportingTime}`,
            `政府回复：${r.GovReplyTime}`,
            `整改完成：${r.FinishTime}`
          ];
          let 举报途径 =
            r.ReportOtherWay !== ""
              ? `${r.ReportWay},${r.ReportOtherWay}`
              : r.ReportWay;
          this.viewData.相关文章.data = r.reportLink;
          this.viewData.现场描述.data = r.Description;
          this.viewData.污染行为.data = r.PolluteAction;
          this.viewData.备注.data = r.Memo;
          this.viewData.现场环境图片.data = [];
          this.viewData.责任主体.data = r.ResponsibleCompay;
          this.viewData.整治后照片.data = [];
          this.viewData.举报途径.data = 举报途径.split(",");
          this.viewData.举报途径.data = delNUll(this.viewData.举报途径.data);
          this.viewData.法律手段.data = r.LawWay.split(",");
          this.viewData.法律手段.data = delNUll(this.viewData.法律手段.data);
          this.viewData.附件.data = [];

          if (r.reportImgs.length > 0) {
            r.reportImgs.map(e => {
              if (e.Type === 1) {
                this.viewData.现场环境图片.data.push({
                  name: e.BigImgUrl,
                  value: e.Remark
                });
              } else if (e.Type === 2) {
                this.viewData.整治后照片.data.push({
                  name: e.BigImgUrl,
                  value: e.Remark
                });
              } else {
                this.viewData.附件.data.push({
                  type: e.Ext.replace(/\s*/g, ""),
                  name: e.Remark,
                  path: e.BigImgUrl
                });
              }
            });
          }
          console.log(this.viewData);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
};
</script>

<style lang="less" scoped>
#submitInfo {
  width: 1197px;
  margin: 0 auto 20px;
  background: #fff;
  min-height: 80vh;
  padding: 0 20px;
  border: 1px solid #eeeeee;
  overflow: auto;
  * {
    box-sizing: border-box;
  }
  .listAll {
    width: 100%;
    display: flex;
    & > div {
      width: 100%;
    }
  }

  .public-title {
    > * {
      font-size: 24px;
      height: 100%;
      line-height: 95px;
      font-weight: bold;
      background: linear-gradient(to right, #46a2da 0%, #79bc53 100%);
      -webkit-background-clip: text;
      color: transparent;
    }
  }
}
</style>
