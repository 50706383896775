<template>
  <div id="submitInfo" v-cloak>
    <div class="public-box">
      <div class="center">
        <p class="page-name">提交详情</p>
        <div class="simple">
          <p class="public-title public-title-title">标题&nbsp;:</p>
          <p class="title">{{ result.Title }}</p>
          <div class="box">
            <div class="box-item position">
              <div>
                <p>位置</p>
                <p>{{ result.Address }}</p>
              </div>
            </div>
            <div class="date-category">
              <div class="box-item date">
                <div>
                  <p>提交日期</p>
                  <p>2019-09-02</p>
                </div>
              </div>
              <div class="box-item category">
                <div>
                  <p>污染类别</p>
                  <p>{{ result.TypeName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="public-title public-title-info">详情描述&nbsp;:</p>
        <p class="info">{{ result.ReportInfo }}</p>
        <p class="public-title public-title-imgList">现场图片&nbsp;:</p>
        <ul class="imgList" v-viewer>
          <li
            class="item"
            v-for="(item, index) in result.ImageList"
            :key="index"
          >
            <img :src="item" />
          </li>
        </ul>
      </div>
    </div>
    <div class="public-box" v-if="result.State >= 4">
      <div class="center">
        <p class="public-title public-title-feedbackInfo">政府反馈&nbsp;:</p>
        <p class="info">{{ result.GovernmentFeedback }}</p>
        <p class="feedback-info"></p>
        <p class="public-title public-title-imgList">政府反馈图片&nbsp;:</p>
        <ul class="imgList" v-viewer>
          <li
            class="item"
            v-for="(item, index) in result.GovFbImageList"
            :key="index"
          >
            <img :src="item" />
          </li>
        </ul>
        <template v-if="result.State === 4">
          <router-link
            class="goToVerification public-btn"
            :to="{ path: 'verification', query: { id: result.ID } }"
            >去验证</router-link
          >
        </template>
      </div>
    </div>
    <div class="public-box">
      <div class="center">
        <template v-if="result.State >= 5">
          <p class="public-title public-title-return">回访详情&nbsp;:</p>
          <p class="info">{{ result.ValidInfo }}</p>
          <p class="public-title public-title-returnImg">回访反馈图片&nbsp;:</p>
          <ul class="imgList" v-viewer>
            <li
              class="item"
              v-for="(item, index) in result.ValidImageList"
              :key="index"
            >
              <img :src="item" />
            </li>
          </ul>
          <p class="public-title public-title-file">附件文件&nbsp;:</p>
          <div class="imgList">
            <template v-for="(item, index) in result.ValidFileList">
              <div
                :key="index"
                class="item"
                v-if="item.sufix === 'doc' || item.sufix === 'docx'"
              >
                <a :href="item.path">
                  <img src="../../assets/26-1.png" alt="" />
                </a>
              </div>
              <div
                :key="index"
                class="item"
                v-if="item.sufix === 'ppt' || item.sufix === 'pptx'"
              >
                <a :href="item.path">
                  <img src="../../assets/27-1.jpg" alt="" />
                </a>
              </div>
              <div :key="index" class="item" v-if="item.sufix === 'pdf'">
                <a :href="item.path">
                  <img src="../../assets/28-1.jpg" alt="" />
                </a>
              </div>
              <div
                :key="index"
                class="item"
                v-if="item.sufix === 'xlsx' || item.sufix === 'xls'"
              >
                <a :href="item.path">
                  <img src="../../assets/25-1.png" alt=""
                /></a>
              </div>
            </template>
          </div>
        </template>
        <p class="public-title public-title-progress">处理进度&nbsp;:</p>
        <div class="step-box">
          <div
            class="step"
            :class="{ active: item.State === 1 }"
            v-for="(item, index) in result.ProgressList"
            :key="index"
          >
            <div>
              <div class="text3">
                <span></span>
              </div>
              <span class="text1">{{ item.Name }}</span>
            </div>
            <span class="text2">{{ item.AddTime }}</span>
          </div>
          <p class="line"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      result: {}
    };
  },
  props: ["id", "type"],
  mounted() {
    this.$http
      .get("/report/api/GetReportModel", {
        params: {
          id: this.id,
          isview: "0",
          type: this.type
        }
      })
      .then(response => {
        if (response.data.state) {
          var result = response.data.data;
          result.ValidFileList.forEach((item, index) => {
            var fileSufixNameObj = {};
            var suffixArr = item.split(".");
            var sufix = suffixArr[suffixArr.length - 1];

            fileSufixNameObj.path = item;
            fileSufixNameObj.sufix = sufix;
            result.ValidFileList[index] = fileSufixNameObj;
          });
          this.result = result;
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
};
</script>

<style lang="less" scoped>
#submitInfo {
  width: 1200px;
  margin: 0 auto 20px;
  .page-name {
    font-size: 22px;
    color: #333333;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .public-box {
    margin-top: 18px;
    background-color: #fff;
    border: 1px solid #eeeeee;
    .center {
      width: 1166px;
      margin: 0 auto;
      .public-title {
        font-size: 16px;
        color: #5a9c85;
        margin-top: 14px;
        &::before {
          content: "";
          display: inline-block;
          margin-right: 10px;
          position: relative;
        }
        &.public-title-title::before {
          width: 18px;
          height: 18px;
          background: url("../../assets/26.png") no-repeat;
          top: 3px;
        }
        &.public-title-info::before,
        &.public-title-feedbackInfo::before {
          width: 18px;
          height: 20px;
          background: url("../../assets/29.png") no-repeat;
          top: 3px;
        }
        &.public-title-imgList::before {
          width: 19px;
          height: 15px;
          background: url("../../assets/32.png") no-repeat;
          top: 2px;
        }
        &.public-title-return::before {
          width: 16px;
          height: 19px;
          background: url("../../assets/34.png") no-repeat;
          top: 3px;
        }
        &.public-title-returnImg::before {
          width: 20px;
          height: 16px;
          background: url("../../assets/35.png") no-repeat;
          top: 3px;
        }
        &.public-title-file::before {
          width: 19px;
          height: 17px;
          background: url("../../assets/36.png") no-repeat;
          top: 3px;
        }
        &.public-title-progress::before {
          width: 20px;
          height: 20px;
          background: url("../../assets/37.png") no-repeat;
          top: 3px;
        }
      }
      .title {
        font-size: 14px;
        color: #777777;
        padding-top: 6px;
      }
      .simple {
        border-bottom: 1px solid #eeeeee;
        .box {
          display: flex;
          justify-content: space-between;
          margin: 20px auto 30px;
          .box-item {
            display: flex;
            p {
              &:nth-child(1) {
                font-size: 16px;
                color: #000000;
                padding-top: 14px;
              }
              &:nth-child(2) {
                font-size: 14px;
                color: #999999;
                padding-top: 6px;
              }
            }
            &::before {
              content: "";
              display: inline-block;
              width: 70px;
              height: 70px;
              border-radius: 50%;
              margin-right: 16px;
            }
            &.position::before {
              background: #f5f5f5 url("../../assets/42.png") center center
                no-repeat;
            }
            &.date::before {
              background: #f5f5f5 url("../../assets/43.png") center center
                no-repeat;
            }
            &.category::before {
              background: #f5f5f5 url("../../assets/44.png") center center
                no-repeat;
            }
          }
          .date-category {
            display: flex;
            .date {
              margin-right: 40px;
            }
          }
        }
      }
      .info {
        font-size: 14px;
        color: #333333;
        text-align: justify;
        line-height: 1.8;
        padding-top: 6px;
      }
      .imgList {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .item {
          width: 88px;
          height: 88px;
          border-radius: 6px;
          margin-right: 10px;
          margin-top: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .step-box {
        width: 290px;
        position: relative;
        .line {
          position: absolute;
          left: 6px;
          top: 31px;
          width: 1px;
          height: auto;
          background-color: #dadada;
          bottom: 31px;
        }
        .step {
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          padding-top: 14px;
          position: relative;
          z-index: 2;
          .text1 {
            font-size: #333333;
            font-size: 14px;
            padding-left: 28px;
            position: relative;
          }
          .text2 {
            font-size: 12px;
            color: #afafaf;
          }
          .text3 {
            width: 11px;
            height: 11px;
            border: 1px solid #2ea446;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 19px;
            background-color: #fff;
          }
          &.active {
            .text3 {
              border: 1px solid #33a64a;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                width: 7px;
                height: 7px;
                background-color: #33a64a;
                border-radius: 50%;
              }
            }
          }
          &:nth-child(6) {
            padding-bottom: 16px;
            &.active {
              .text3 {
                background-color: #33a64a;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  width: 55% !important;
                  height: 25% !important;
                  border: 2px solid #fff;
                  border-radius: 1px !important;
                  border-top: none;
                  border-right: none;
                  background: transparent;
                  transform: rotate(-45deg);
                  position: relative;
                  top: -1px;
                }
              }
            }
          }
        }
      }
      .goToVerification {
        margin: 0 auto 20px;
        &::before {
          content: "";
          display: inline-block;
          width: 23px;
          height: 19px;
          background: url("../../assets/46.png") no-repeat;
          position: relative;
          top: 3px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
