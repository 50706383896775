<template>
  <div>
    <OldPage
      :id="id != undefined ? id : this.$route.query.id"
      :type="type != undefined ? type : this.$route.query.type"
      v-if="type === 0 || type === '0'"
    />
    <NewPage :id="id" :type="type" v-else />
  </div>
</template>

<script>
import NewPage from "./NewPage";
import OldPage from "./OldPage";
export default {
  props: ["id", "type"],
  components: {
    NewPage,
    OldPage
  }
};
</script>

<style lang="scss" scoped></style>
