<!--
 * @Copyright: 12308
 * @Author: ShiJunJie
 * @Date: 2020-12-03 09:55:28
 * @LastEditors: ShiJunJie
 * @LastEditTime: 2021-01-27 18:10:56
 * @Descripttion: 
-->
<template>
  <div
    class="public-box"
    :key="index"
    v-if="item.data !== '' && item.data.length > 0"
  >
    <i :class="item.icon"></i>
    <div class="title">
      <p>{{ item.name }}：</p>
    </div>

    <!-- 文本 -->
    <template v-if="item.type === 'text'">
      <div class="font">
        <p>{{ item.data }}</p>
      </div>
    </template>

    <!-- 文章 -->
    <template v-if="item.type === 'url'">
      <div class="link">
        <a
          target="_bank"
          v-for="(link, index) in item.data"
          :key="index"
          :href="link.Link"
          >{{ link.Title }}</a
        >
      </div>
    </template>

    <!-- 图片 -->
    <template v-else-if="item.type === 'imgs'">
      <ul class="imgList" v-viewer>
        <li class="item" v-for="(imgs, index) in item.data" :key="index">
          <img
            :src="imgs.name ? imgs.name : imgs"
            :title="imgs.value ? imgs.value : imgs"
            :alt="imgs.value ? imgs.value : imgs"
          />
        </li>
      </ul>
    </template>

    <!-- 列表 -->
    <template v-else-if="item.type === 'list'">
      <div class="list">
        <p v-for="(e, index) in item.data" :key="index">{{ e }}</p>
      </div>
    </template>

    <!-- 文件 -->
    <div class="file_" v-else-if="item.type === 'file'">
      <template v-for="(e, index) in item.data">
        <div :key="index" class="item">
          <template
            v-if="
              e.type === 'png' ||
                e.type === 'jpg' ||
                e.type === 'jpeg' ||
                e.type === 'gif'
            "
          >
            <a :href="e.path" class="f" target="_blank">
              <img :src="e.path" class="imgIcon" />
              <span>{{ e.name }}</span>
            </a>
          </template>
          <template v-else>
            <a :href="e.path" class="f" download="" target="_blank">
              <template v-if="e.type === 'doc' || e.type === 'docx'">
                <img src="../../assets/26-1.png" />
              </template>
              <template v-else-if="e.type === 'ppt' || e.type === 'pptx'">
                <img src="../../assets/27-1.jpg" />
              </template>
              <template v-else-if="e.type === 'pdf'">
                <img src="../../assets/28-1.jpg" />
              </template>
              <template v-else-if="e.type === 'xlsx' || e.type === 'xls'">
                <img src="../../assets/25-1.png" />
              </template>
              <span>{{ e.name }}</span>
            </a>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "index"]
};
</script>

<style lang="less" scoped>
.public-box {
  position: relative;
  float: left;
  padding-left: 50px;
  margin-bottom: 30px;
  padding-right: 80px;
  width: 100%;
  //   width: 50%;
  i {
    position: absolute;
    left: 20px;
  }
  .title {
    font-size: 20px;
    color: #333;
    margin-bottom: 5px;
  }
  .font {
    * {
      font-size: 16px;
      color: #505050;
    }
  }
  .link {
    display: block;
    margin-bottom: 5px;
    a {
      display: block;
      & + a {
        margin-top: 10px;
      }
    }
  }
  .list {
    > * {
      position: relative;
      font-size: 16px;
      color: #505050;
      padding-left: 20px;
      line-height: 20px;
      margin-bottom: 5px;
      &::before {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        background: #505050;
        border-radius: 100%;
        left: 8px;
        top: 8px;
      }
    }
  }
  .file_ {
    .item {
      margin-bottom: 8px;
    }
    /deep/ .f {
      display: flex;
      width: 100%;
      img {
        min-width: 30px;
        max-width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        &.imgIcon {
          box-sizing: border-box;
          padding: 1.5px;
          border: 1px solid #309bd4;
        }
      }
      span {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }

  .imgList {
    display: flex;
    flex-wrap: wrap;
    // margin-bottom: 20px;
    .item {
      width: 70px;
      height: 70px;
      border-radius: 6px;
      margin-right: 5px;
      margin-top: 5px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
